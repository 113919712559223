import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/model.css'

import { Button, Input } from 'components'

class Model extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      answers: {},
    }
  }

  update(key, value) {
    this.setState({
      answers: Object.assign({}, this.state.answers, {
        [key]: value,
      }),
    })
  }

  render() {
    const cl = bem('model')

    return (
      <div className={cl.toString()}>
        <h2>Model Information</h2>

        <Button onClick={() => this.props.finish(this.state.answers)} block>
          Submit & view report
        </Button>
      </div>
    )
  }
}

export default Model
