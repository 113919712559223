import React, { useReducer, useEffect } from 'react'
import { Route, Redirect, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import data from 'data'
import { clean } from 'lib/utils'

function DimensionPage(props) {
  const dimensionKey = props.match.params.dimension
  const dimension = data.dimensions[dimensionKey]
  console.log(dimension)

  return (
    <div>
      <Helmet>
        <title>The {dimension.name} dimension | 5✗10 Maturity Model</title>
        <meta
          name="description"
          content={`
          ${dimension.short} Learn more about the ${dimension.name} dimension of the 5✗10
          Maturity Model.`}
        />
      </Helmet>

      <div className="stage-page__nav">
        {data.dimensions.index.map(dimension => (
          <Link
            key={dimension}
            to={`/model/dimensions/${dimension}`}
            className={`stage-page__nav-item ${dimension == dimensionKey &&
              'stage-page__nav-item--active'}`}
          >
            {data.dimensions[dimension].name}
          </Link>
        ))}
      </div>

      <h1>{dimension.name}</h1>
      <p>
        <i>{dimension.short}</i>
      </p>

      <p>{clean(dimension.full)}</p>
    </div>
  )
}

export default DimensionPage
