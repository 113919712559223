import 'whatwg-fetch'
import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/assessment.css'
import { Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import General from './general'
import Dimension from './dimension'
import Instructions from './instructions'
import Model from './model'
import Stages from './stages'
import Report from './report'

import data from 'data'
import { reducer, initialState, init } from '../../lib/reducer'

function progress() {
  const url = window.location.pathname
  if (url.includes('general')) return 5
  if (url.includes('stages')) return 10
  if (url.includes('data')) return 15
  if (url.includes('metrics')) return 22
  if (url.includes('skills')) return 29
  if (url.includes('technology')) return 36
  if (url.includes('leadership')) return 43
  if (url.includes('culture')) return 50
  if (url.includes('strategy')) return 57
  if (url.includes('agility')) return 64
  if (url.includes('integration')) return 71
  if (url.includes('empowerment')) return 80
  if (url.includes('report')) return 100
  return 2
}

function Assessment(props) {
  const [state, dispatch] = useReducer(reducer, initialState, init)

  useEffect(() => {
    const url = new URL(window.location.href)
    const id = url.searchParams.get('id')
    if (id) {
      dispatch({ type: 'RETRIEVE_ASSESSMENT', payload: { dispatch, id } })
    }
  }, true)

  // useEffect(() => {
  //   dispatch({ type: 'load' })
  //   dispatch({ type: 'save' })
  // })

  // saveData(data = {}) {
  //   this.setState({
  //     answers: Object.assign({}, state.answers, data.answers),
  //     meta: Object.assign({}, state.meta, data.meta),
  //   })
  // }

  // finish(meta = {}) {
  //   this.setState({
  //     meta: Object.assign({}, state.meta, meta),
  //   })
  // }

  const cl = bem('assessment')

  useEffect(() => {
    if (!state.pushedURL) return

    props.history.push(state.pushedURL)
    dispatch({ type: 'PUSHED_URL' })
  }, [state.pushedURL])

  return (
    <div className={cl.toString()}>
      <Helmet>
        <title>The 5✗10 Data-Driven Maturity Assessment</title>
        <meta
          name="description"
          content="The 5✗10 Data-Driven Maturity Assessment is an accessible self-assessment (+/- 20 minutes) that results in a personalized maturity report with advice tailored to your current maturity."
        />
      </Helmet>

      <div
        className={cl('progress').toString()}
        style={{ width: `${progress(state)}%` }}
        onClick={() =>
          window.confirm('Are you sure you want to reset the assessment?') &&
          dispatch({ type: 'RESET' })
        }
      />

      <Route
        exact
        path={`${props.match.url}`}
        render={props => <Instructions state={state} dispatch={dispatch} />}
      />

      <Route
        path={`${props.match.url}/general`}
        render={props => <General state={state} dispatch={dispatch} />}
      />
      <Route
        path={`${props.match.url}/stages`}
        render={props => <Stages state={state} dispatch={dispatch} />}
      />
      <Route
        path={`${props.match.url}/dimensions/:dimension`}
        render={props => (
          <Dimension
            state={state}
            dispatch={dispatch}
            dimensionKey={props.match.params.dimension}
          />
        )}
      />
      <Route
        path={`${props.match.url}/report`}
        render={props => <Report global={state} dispatch={dispatch} />}
      />
    </div>
  )
}

export default Assessment
