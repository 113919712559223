import { useEffect } from 'react'

function useGoToTop({ key, behavior } = {}) {
  useEffect(
    () => {
      window.scrollTo({
        top: 0,
        behavior: behavior || 'smooth',
      })
    },
    key ? [key] : [true]
  )
}

function updateMetaAnswer(setState, localState, key, value) {
  setState(
    Object.assign({}, localState, {
      meta: Object.assign({}, localState.meta, {
        [key]: value,
      }),
    })
  )
}

function updateAnswer(setState, localState, key, value) {
  setState(
    Object.assign({}, localState, {
      answers: Object.assign({}, localState.answers, {
        [key]: value,
      }),
    })
  )
}

function firstUnanswered(answers, keys) {
  return keys.find(key => !answers[key] && answers[key] !== 0)
}

export { useGoToTop, updateAnswer, updateMetaAnswer, firstUnanswered }
