import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/scalar.css'

class Scalar extends React.Component {
  render() {
    if (!this.props.showMeta) return null

    const cl = bem('scalar', 'no-show')

    return (
      <div className={cl.toString()} id={this.props.id}>
        <div className={cl('title').toString()}>{this.props.question}</div>
        <div className={cl('scale').toString()}>
          <div>←{this.props.min}</div>
          <div>{this.props.max}→</div>
        </div>
        <div className={cl('options').toString()}>
          {Array(11)
            .fill(true)
            .map((option, index) => (
              <div
                className={cl('option', {
                  $selected: index === this.props.currentAnswer,
                }).toString()}
                onClick={() => this.props.answer(this.props.id, index)}
                key={index}
              >
                {index}
                <br />
              </div>
            ))}
        </div>
      </div>
    )
  }
}

export default Scalar
