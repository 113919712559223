import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/general.css'

import { Button, Input } from 'components'
import { Link } from 'react-router-dom'
import Scalar from './scalar'
import Likert from './likert'
import { useGoToTop, updateMetaAnswer, firstUnanswered } from '../../lib/common'

function allAnswered(localState, showMeta) {
  const keys = ['organization-name', 'evaluator-name', 'role']
  const allQuestions = firstUnanswered(localState.meta, keys)

  const metaKeys = [
    'size',
    'data-driven-intent',
    'data-driven-plan',
    'data-driven-information',
    'data-driven-level',
  ]
  const allMeta = firstUnanswered(localState.meta, metaKeys)

  return !allQuestions && (!showMeta || !allMeta)
}

function General({ state, dispatch }) {
  const [localState, setState] = useState({
    answers: {},
    meta: state.meta.general || {},
  })

  useGoToTop()
  const cl = bem('general')

  return (
    <div className={cl.toString()}>
      <h2>General Information</h2>
      <span>
        The assessment scope is a self-defined scope. In larger organizations it
        can be hard to determine the overall data-driven maturity level. Setting
        a scope (e.g. business unit, company division, product team) will help
        you contextualize the questions and help you determine maturity more
        accurately. The overall data-driven maturity can be attained by
        combining the results of several assessments.
      </span>

      <Input
        showMeta={true}
        description="Organization name (featured on final report)"
        update={value =>
          updateMetaAnswer(setState, localState, 'organization-name', value)
        }
        value={localState.meta['organization-name']}
      />
      <Input
        showMeta={true}
        description="Assessment scope (optional, featured on final report)"
        update={value => updateMetaAnswer(setState, localState, 'scope', value)}
        value={localState.meta['scope']}
      />
      <Input
        showMeta={true}
        description="Evaluator name (featured on final report)"
        update={value =>
          updateMetaAnswer(setState, localState, 'evaluator-name', value)
        }
        value={localState.meta['evaluator-name']}
      />
      <Input
        showMeta={true}
        description="Evaluator role (featured on final report)"
        update={value => updateMetaAnswer(setState, localState, 'role', value)}
        value={localState.meta['role']}
      />
      <Input
        showMeta={state.showMeta}
        description="Approximate number of employees in the organization"
        update={value => updateMetaAnswer(setState, localState, 'size', value)}
        value={localState.meta['size']}
      />

      <Likert
        showMeta={state.showMeta}
        answer={(id, answer) =>
          updateMetaAnswer(setState, localState, id, answer)
        }
        id={'data-driven-intent'}
        question="My organization is planning to become data-driven."
        currentAnswer={localState.meta['data-driven-intent']}
      />

      <Likert
        showMeta={state.showMeta}
        answer={(id, answer) =>
          updateMetaAnswer(setState, localState, id, answer)
        }
        id={'data-driven-plan'}
        question="We have a clear idea on how to become data-driven."
        currentAnswer={localState.meta['data-driven-plan']}
      />

      <Likert
        showMeta={state.showMeta}
        answer={(id, answer) =>
          updateMetaAnswer(setState, localState, id, answer)
        }
        id={'data-driven-information'}
        question="We can use more information on how to become data-driven."
        currentAnswer={localState.meta['data-driven-information']}
      />

      <Scalar
        showMeta={state.showMeta}
        answer={(id, answer) =>
          updateMetaAnswer(setState, localState, id, answer)
        }
        id={`data-driven-level`}
        question="How data-driven is your organization now?"
        min="The organization is not data-driven at all"
        max="The organization is fully data-driven"
        currentAnswer={localState.meta[`data-driven-level`]}
      />

      {allAnswered(localState, state.showMeta) ? (
        <Button
          to="/assessment/stages"
          onClick={() =>
            dispatch({
              type: 'SUBMIT_ANSWERS',
              payload: {
                key: 'general',
                meta: localState.meta,
                answers: localState.answers,
              },
            })
          }
          block
        >
          Continue
        </Button>
      ) : (
        <Button block>Please answer all questions before continuing</Button>
      )}
    </div>
  )
}

export default General
