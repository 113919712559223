import React, { useReducer, useEffect } from 'react'
import { Route, Redirect, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { clean } from 'lib/utils'
import data from 'data'
import 'styles/pages/stage-page.css'

function StagePage(props) {
  const stageName = props.match.params.stage
  const stage = data.stages[stageName]

  return (
    <div>
      <Helmet>
        <title>The {stage.name} stage | 5✗10 Maturity Model</title>
        <meta
          name="description"
          content={`
          ${stage.short} Learn more about the ${stage.name} dimension of the 5✗10
          Maturity Model.`}
        />
      </Helmet>

      <div className="stage-page__nav">
        {data.stages.index.map(stage => (
          <Link
            key={stage}
            to={`/model/stages/${stage}`}
            className={`stage-page__nav-item ${stage == stageName &&
              'stage-page__nav-item--active'}`}
          >
            {data.stages[stage].name}
          </Link>
        ))}
      </div>

      <h1>{stage.name}</h1>
      <p>
        <i>{stage.short}</i>
      </p>

      <p>{clean(stage.full)}</p>

      {data.dimensions.index.map(dimension => (
        <div key={dimension} className="introduction__section">
          <h2>
            {data.dimensions[dimension].name} in the {stage.name} stage
          </h2>

          <p>{clean(stage[dimension].text)}</p>

          <ul className="stage-page__action-list">
            {stage[dimension].actionList.map(action => (
              <li>{action}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

export default StagePage
