import React from 'react'
import bem from 'bem-classes'
import { bindActionCreators } from 'redux'
import { Link, withRouter } from 'react-router-dom'

import 'styles/blocks/header.css'

const Header = props => {
  const header = bem('header')

  return (
    <header className={header('', 'no-print').toString()}>
      <Link to="/" className={header('title').toString()}>
        5&#x2717;10
      </Link>

      <nav className="header__nav">
        <Link to="/model" className="header__nav-item">
          Model
        </Link>
        <Link to="/assessment" className="header__nav-item">
          Assessment
        </Link>
      </nav>
    </header>
  )
}

export default withRouter(Header)
