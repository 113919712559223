import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/chart.css'
import { capitalize } from 'lib/utils'
import { Button, Input } from 'components'
import * as d3 from 'd3'

//Wraps SVG text
function wrap(text, width) {
  text.each(function() {
    var text = d3.select(this),
      words = text
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 0, // ems
      y = text.attr('y'),
      x = text.attr('x'),
      dy = parseFloat(text.attr('dy')),
      tspan = text
        .text(null)
        .append('tspan')
        .attr('x', x)
        .attr('y', y)
        .attr('dy', dy + 'em')

    while ((word = words.pop())) {
      line.push(word)
      tspan.text(line.join(' '))
      if (tspan.node().getComputedTextLength() > width) {
        line.pop()
        tspan.text(line.join(' '))
        line = [word]
        tspan = text
          .append('tspan')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(word)
      }
    }
  })
} //wrap

class Chart extends React.Component {
  constructor(props) {
    super(props)

    const scores = props.scores
    this.state = {
      data: Object.keys(scores).map(key => ({
        axis: key,
        value: scores[key],
      })),
    }
  }

  componentDidMount() {
    const HALF_PI = Math.PI / 2

    const data = [this.state.data]
    const id = '.report__chart'
    const config = {
      width: 400,
      height: 400,
      margin: {
        left: 100,
        top: 100,
        right: 100,
        bottom: 100,
      },
      circleOpacity: 0,
      axisColor: '#000',
      axisOpacity: 0.1,
      areaOpacity: 0.7,
      levels: 5,
      maxValue: 5,
      labelFactor: 1.25,
      wrapWidth: 60,
      color: d3.scaleOrdinal(d3.schemeCategory10),
      roundStrokes: true,
    }

    //If the supplied maxValue is smaller than the actual one, replace by the max in the data
    var maxValue = Math.max(
      config.maxValue,
      d3.max(data, function(i) {
        return d3.max(
          i.map(function(o) {
            return o.value
          })
        )
      })
    )

    //Names of each axis
    const allAxis = data[0].map(function(i, j) {
      return capitalize(i.axis)
    })
    const radius = Math.min(config.width / 2, config.height / 2) //Radius of the outermost circle
    const Format = d3.format('%')
    const angleSlice = (Math.PI * 2) / allAxis.length //The width in radians of each "slice"
    //Scale for the radius
    const rScale = d3
      .scaleLinear()
      .range([0, radius])
      .domain([0, maxValue])

    const svg = d3
      .select(id)
      .append('svg')
      .attr('width', '100%')
      .attr(
        'viewBox',
        `0 0 ${config.width +
          config.margin.left +
          config.margin.right} ${config.height +
          config.margin.top +
          config.margin.bottom}`
      )

    const g = svg
      .append('g')
      .attr(
        'transform',
        'translate(' +
          (config.width / 2 + config.margin.left) +
          ',' +
          (config.height / 2 + config.margin.top) +
          ')'
      )

    //Wrapper for the grid & axes
    const axisGrid = g.append('g').attr('class', 'axisWrapper')

    //Filter for the outside glow
    const filter = g
      .append('defs')
      .append('filter')
      .attr('id', 'glow')
    const feGaussianBlur = filter
      .append('feGaussianBlur')
      .attr('stdDeviation', '2.5')
      .attr('result', 'coloredBlur')
    const feMerge = filter.append('feMerge')
    const feMergeNode_1 = feMerge
      .append('feMergeNode')
      .attr('in', 'coloredBlur')
    const feMergeNode_2 = feMerge
      .append('feMergeNode')
      .attr('in', 'SourceGraphic')

    const colors = ['#ff7580', '#ffc37e', '#54ce98', '#6cb5ed', '#9d92ef']
    //Draw the background circles
    axisGrid
      .selectAll('.levels')
      .data(d3.range(1, config.levels + 1).reverse())
      .enter()
      .append('circle')
      .attr('class', 'gridCircle')
      .attr('r', function(d, i) {
        return (radius / config.levels) * d
      })
      .style('fill', (d, i) => colors[d - 1])
      .style('stroke', (d, i) => colors[d - 1])
      .style('fill-opacity', config.circleOpacity)
      .style('filter', 'url(#glow)')

    const maturityLevels = [
      '',
      'Reporting',
      'Analyzing',
      'Optimizing',
      'Empowering',
      'Innovating',
    ]

    //Text indicating at what % each level is
    // axisGrid
    //   .selectAll('.axisLabel')
    //   .data(d3.range(1, config.levels + 1).reverse())
    //   .enter()
    //   .append('text')
    //   .attr('class', 'axisLabel')
    //   .attr('text-anchor', 'middle')
    //   .attr('x', 4)
    //   .attr('y', function(d) {
    //     return -d * radius / config.levels
    //   })
    //   .attr('dy', '0.4em')
    //   .style('font-size', '16px')
    //   .style('width', '200px')
    //   .attr('fill', '#000000')
    //   .text(function(d, i) {
    //     return maturityLevels[d]
    //   })

    /////////////////////////////////////////////////////////
    //////////////////// Draw the axes //////////////////////
    /////////////////////////////////////////////////////////

    //Create the straight lines radiating outward from the center
    const axis = axisGrid
      .selectAll('.axis')
      .data(allAxis)
      .enter()
      .append('g')
      .attr('class', 'axis')
    //Append the lines
    axis
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', function(d, i) {
        return rScale(maxValue * 1.1) * Math.cos(angleSlice * i - Math.PI / 2)
      })
      .attr('y2', function(d, i) {
        return rScale(maxValue * 1.1) * Math.sin(angleSlice * i - Math.PI / 2)
      })
      .attr('class', 'line')
      .style('stroke', config.axisColor)
      .style('stroke-width', '2px')
      .style('opacity', config.axisOpacity)

    //Append the labels at each axis
    axis
      .append('text')
      .attr('class', 'legend')
      .style('font-size', '18px')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'central')
      .attr('dy', '0em')
      .attr(
        'x',
        (d, i) =>
          rScale(maxValue * config.labelFactor) *
          Math.cos(angleSlice * i - HALF_PI)
      )
      .attr(
        'y',
        (d, i) =>
          rScale(maxValue * config.labelFactor) *
          Math.sin(angleSlice * i - HALF_PI)
      )
      .text(d => d)
      .call(wrap, config.wrapWidth)

    /////////////////////////////////////////////////////////
    ///////////// Draw the radar chart blobs ////////////////
    /////////////////////////////////////////////////////////

    //The radial line function
    const radarLine = d3
      .radialLine()
      .curve(d3.curveLinear)
      .radius(function(d) {
        return rScale(d.value)
      })
      .angle(function(d, i) {
        return i * angleSlice
      })

    if (config.roundStrokes) {
      radarLine.curve(d3.curveCardinalClosed)
    }

    //Create a wrapper for the blobs
    const blobWrapper = g
      .selectAll('.radarWrapper')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'radarWrapper')

    //Append the backgrounds
    blobWrapper
      .append('path')
      .attr('class', 'radarArea')
      .attr('d', function(d, i) {
        return radarLine(d)
      })
      .style('fill', function(d, i) {
        return config.color(i)
      })
      .style('fill-opacity', config.areaOpacity)
      .on('mouseover', function(d, i) {
        //Dim all blobs
        d3.selectAll('.radarArea')
          .transition()
          .duration(200)
          .style('fill-opacity', 0.1)
        //Bring back the hovered over blob
        d3.select(this)
          .transition()
          .duration(200)
          .style('fill-opacity', 1)
      })
      .on('mouseout', function() {
        //Bring back all blobs
        d3.selectAll('.radarArea')
          .transition()
          .duration(200)
          .style('fill-opacity', config.areaOpacity)
      })

    //Create the outlines
    blobWrapper
      .append('path')
      .attr('class', 'radarStroke')
      .attr('d', function(d, i) {
        return radarLine(d)
      })
      .style('stroke-width', config.strokeWidth + 'px')
      .style('stroke', function(d, i) {
        return config.color(i)
      })
      .style('fill', 'none')
      .style('filter', 'url(#glow)')

    //Append the circles
    blobWrapper
      .selectAll('.radarCircle')
      .data(function(d, i) {
        return d
      })
      .enter()
      .append('circle')
      .attr('class', 'radarCircle')
      .attr('r', config.dotRadius)
      .attr('cx', function(d, i) {
        return rScale(d.value) * Math.cos(angleSlice * i - Math.PI / 2)
      })
      .attr('cy', function(d, i) {
        return rScale(d.value) * Math.sin(angleSlice * i - Math.PI / 2)
      })
      .style('fill', function(d, i, j) {
        return config.color(j)
      })
      .style('fill-opacity', 0.8)
  }

  render() {
    return <div className="report__chart" />
  }
}

export default Chart
