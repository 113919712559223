import moment from 'moment'

function uploadAssessment(dispatch, state) {
  fetch('https://store.rubenbuitelaar.com', {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type: 'assessment',
      answers: state.answers,
      meta: state.meta,
      date: moment().format('Do of MMMM, YYYY'),
    }),
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: 'SUBMITTED_ASSESSMENT',
        payload: {
          submitted: data.id,
          date: moment().format('Do of MMMM, YYYY'),
        },
      })
    })
}

function retrieveAssessment(state, { dispatch, id }) {
  fetch('https://store.rubenbuitelaar.com', {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id,
    }),
  })
    .then(res => res.json())
    .then(assessment => {
      dispatch({ type: 'RETRIEVED_ASSESSMENT', payload: { id, assessment } })
    })

  return state
}

export { uploadAssessment, retrieveAssessment }
