import React, { useReducer, useEffect } from 'react'
import 'styles/pages/model-page.css'
import { Link, Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Model } from 'components'
import data from 'data'
import StagePage from './stage-page'
import DimensionPage from './dimension-page'

const stages = [
  {
    name: 'Reporting',
    content:
      'Visualize existing data and create the foundation for an analytical future.',
  },
  {
    name: 'Analyzing',
    content:
      'Dive deeper into the data to achieve insight into why things happened.',
  },
  {
    name: 'Optimizing',
    content:
      'Optimize business processes by bringing analytical insight to operations.',
  },
  {
    name: 'Empowering',
    content:
      'Empower employees by providing the tools and knowledge to perform analytical activities.',
  },
  {
    name: 'Innovating',
    content:
      'Use data and experiments to innovate in products and transform the organization.',
  },
]

const dimensions = [
  {
    name: 'Data',
    content:
      'The fuel for all data-driven activities. How do you source and manage your data?',
  },
  {
    name: 'Metrics',
    content:
      "The key to measuring output and managing performance. How do you use, collect, and enrich your KPI's?",
  },
  {
    name: 'Skills',
    content:
      'Essential for operating a data-driven organization. Do you hire and educate the right people?',
  },
  {
    name: 'Technology',
    content:
      'The foundation for a data-driven organization. What technology do you need to build an analytical process?',
  },
  {
    name: 'Leadership',
    content:
      'The cornerstone for a successful analytical transformation. How does leadership successfully steer the transformation?',
  },
  {
    name: 'Culture',
    content:
      'The driving force behind a data-driven organization. How does culture affect and promote data-driven adoption?',
  },
  {
    name: 'Strategy',
    content:
      'The plan for success. What role does analytics have in your plans and vision of the future?',
  },
  {
    name: 'Agility',
    content:
      'The ability to adapt and succeed. How well are your roles and processes organized to change and deliver?',
  },
  {
    name: 'Integration',
    content:
      'The integration of analytical insight into processes. How is the organization using analytical output and how are operational processes using real-time intelligence?',
  },
  {
    name: 'Empowerment',
    content:
      'The empowerment of the organization. How is data analytics helping your employees to succeed?',
  },
]

function MaturityModel(props) {
  const dimensions = data.dimensions

  return (
    <>
      <Helmet>
        <title>The 5✗10 Data-Driven Maturity Model</title>
        <meta
          name="description"
          content="The 5✗10 Data-Driven Maturity Model describes the journey of an
        analytically impaired organization to an innovative organization
        capturing maximum value from their data."
        />
      </Helmet>

      <h1>The 5&#x2717;10 Data-Driven Maturity Model</h1>
      <Model />
      <p>
        The 5✗10 Data-Driven Maturity Model describes the journey of an
        analytically impaired organization to an innovative organization
        capturing maximum value from their data. The accompanying{' '}
        <Link to="/assessment">5✗10 Data-Driven Maturity Assessment</Link> is an
        accessible self-assessment (+/- 20 minutes) that positions the
        organization on the maturity model and provides tailored information on
        the current state of your organization.
      </p>

      <p>
        The maturity model consists of 5 stages by 10 dimensions. The stages
        progress from a basic level of data-driven maturity to full data-driven
        maturity. The maturity model builds on existing research in business
        intelligence & analytics maturity and expands on this in the later
        stages and in additional dimensions.
      </p>

      <h2 className="h2--margin">The 5 stages of data-driven maturity</h2>
      {stages.map(stage => (
        <div className="model-page__stages" key={stage.name}>
          <h3
            className={`
              model-page__stage-header
              model-page__stage-header--${stage.name.toLowerCase()}`}
          >
            {stage.name}
          </h3>
          <p>{stage.content}</p>
          <div>
            <Link to={`/model/stages/${stage.name.toLowerCase()}`}>
              Read more on the {stage.name} stage
            </Link>
          </div>
        </div>
      ))}

      <h2 className="h2--margin">The 10 dimensions of data-driven maturity</h2>
      {dimensions.index.map(key => (
        <div className="model-page__dimensions" key={key}>
          <h3
            className={`
              model-page__dimension-header
              model-page__dimension-header--${key}`}
          >
            {dimensions[key].name}
          </h3>
          <p>{dimensions[key].short}</p>
          <div>
            <Link to={`/model/dimensions/${key}`}>
              Read more on the {dimensions[key].name} dimension
            </Link>
          </div>
        </div>
      ))}
    </>
  )
}

function Index(props) {
  return (
    <>
      <Helmet>
        <title>Data-Driven Maturity Model</title>
      </Helmet>

      <Route exact path="/model" component={MaturityModel} />
      <Route path="/model/stages/:stage" component={StagePage} />
      <Route path="/model/dimensions/:dimension" component={DimensionPage} />
    </>
  )
}

export default Index
