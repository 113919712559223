import React from 'react'
import bem from 'bem-classes'
import 'styles/blocks/introduction.css'

import data from 'data'
import { Model, Button } from 'components'
import { Link, withRouter } from 'react-router-dom'

const Introduction = props => {
  const intro = bem('introduction')

  const stages = [
    {
      name: 'Reporting',
      content:
        'Visualize existing data and create the foundation for an analytical future.',
    },
    {
      name: 'Analyzing',
      content:
        'Dive deeper into the data to achieve insight into why things happened.',
    },
    {
      name: 'Optimizing',
      content:
        'Optimize business processes by bringing analytical insight to operations.',
    },
    {
      name: 'Empowering',
      content:
        'Empower employees by providing the tools and knowledge to perform analytical activities.',
    },
    {
      name: 'Innovating',
      content:
        'Use data and experiments to innovate in products and transform the organization.',
    },
  ]

  const dimensions = [
    {
      name: 'Data',
      content:
        'The fuel for all data-driven activities. How do you source and manage your data?',
    },
    {
      name: 'Metrics',
      content:
        "The key to measuring output and managing performance. How do you use, collect, and enrich your KPI's?",
    },
    {
      name: 'Skills',
      content:
        'Essential for operating a data-driven organization. Do you hire and educate the right people?',
    },
    {
      name: 'Technology',
      content:
        'The foundation for a data-driven organization. What technology do you need to build an analytical process?',
    },
    {
      name: 'Leadership',
      content:
        'The cornerstone for a successful analytical transformation. How does leadership successfully steer the transformation?',
    },
    {
      name: 'Culture',
      content:
        'The driving force behind a data-driven organization. How does culture affect and promote data-driven adoption?',
    },
    {
      name: 'Strategy',
      content:
        'The plan for success. What role does analytics have in your plans and vision of the future?',
    },
    {
      name: 'Agility',
      content:
        'The ability to adapt and succeed. How well are your roles and processes organized to change and deliver?',
    },
    {
      name: 'Integration',
      content:
        'The integration of analytical insight into processes. How is the organization using analytical output and how are operational processes using real-time intelligence?',
    },
    {
      name: 'Empowerment',
      content:
        'The empowerment of the organization. How is data analytics helping your employees to succeed?',
    },
  ]

  return (
    <div className={intro.toString()}>
      <div className="fiveten">
        <div className="fiveten__introducing">Introducing</div>
        fiveten data-driven <br /> maturity platform
        <a className="fiveten__tagline" href="https://www.fiveten.io">
          Join the closed beta
        </a>
      </div>

      <h1 className="introduction__header">
        The <span className="brand">5✗10</span> Data-Driven Maturity Framework
      </h1>

      <div className="alert alert--green">
        Bring your organization to the next level. <br />
        Take the free data-driven maturity assessment and discover the best path
        for your organization.
      </div>

      <div className={intro('usps').toString()}>
        <div className={intro('usp').toString()}>
          <span className={intro('usp-title').toString()}>Insightful</span>
          <span className={intro('usp-text').toString()}>
            Generates a personalized report with executive summary
          </span>
        </div>

        <div className={intro('usp').toString()}>
          <span className={intro('usp-title').toString()}>Fast</span>
          <span className={intro('usp-text').toString()}>
            The maturity assessment takes less than 30 minutes
          </span>
        </div>
        <div className={intro('usp').toString()}>
          <span className={intro('usp-title').toString()}>Free</span>
          <span className={intro('usp-text').toString()}>
            For a limited time the assessment is free to use
          </span>
        </div>
      </div>

      <Button to="/assessment">
        Start the 5✗10 Data-Driven Maturity Assessment
      </Button>

      <div className="introduction__section">
        <div className="introduction__section-columns">
          <div className="introduction__copy">
            <h2>The 5✗10 framework: model, assessment & report</h2>
            <p>
              The framework consists of a{' '}
              <Link to="/model">maturity model</Link>,{' '}
              <Link to="/assessment">maturity assessment</Link>, and maturity
              report. The model consists of 5 stages of maturity, progressing
              from an analytically impaired organization to an innovating
              data-driven organization. Maturity is determined on 10 dimensions
              that together fully cover all aspects of being data-driven. It
              considers both technical capabilities and organizational
              capabilities.
            </p>
          </div>
          <div className="introduction__section-img">
            <img
              src={`${process.env.PUBLIC_URL}/stages-fancy.png`}
              alt="Stages of the 5✗10 Data-Driven Maturity Model"
            />
          </div>
        </div>
      </div>

      <div className="introduction__section">
        <h2 className="h2--margin">
          Bridge the gap in business intelligence & analytics
        </h2>
        <p>
          Many organizations fail to take full advantage of analytics and
          struggle to become a data-driven company. They may grasp the basics of
          business intelligence and analytics but are not turning analytical
          competence into a competitive advantage. Data-driven organizations
          successfully leverage analytical competence into value for their
          customers.
        </p>
      </div>

      <div className="introduction__section">
        <div className="introduction__section-columns introduction__section-columns--reverse">
          <div className="introduction__copy">
            <h2>A maturity model for the data-driven organization</h2>
            <p>
              The <Link to="/model">5✗10 maturity model</Link> provides a
              360-view on the data-driven capabilities of your organization. The{' '}
              <Link to="/assessment">5✗10 maturity assessment</Link> determines
              both organizational and technical capabilities. How well is your
              organization performing on technical abilities? But perhaps more
              importantly, how is data woven into the DNA of your organization?
              Valuable insight is provided in a personalized executive summary.
            </p>
          </div>
          <img
            className="introduction__section-img"
            src={`${process.env.PUBLIC_URL}/score-chart.png`}
            alt="Data-Driven Maturity Score-card"
          />
        </div>
      </div>

      <div className="introduction__section">
        <div className="introduction__section-columns">
          <div className="introduction__copy">
            <h2>A quick and accessible maturity assessment</h2>
            <p>
              The{' '}
              <Link to="/assessment">5✗10 Data-Driven Maturity Assessment</Link>{' '}
              is an accessible self-assessment (+/- 20 minutes). Questions don't
              require extensive technical knowledge and are easy to answer. Just
              select the statement most fitting to the current state of the
              organization. Combine different viewpoints in your organization to
              create a 360-view on the state of analytical maturity in your
              organization. Track results over time to measure the progress your
              organization is making in its digital transformation.
            </p>
          </div>
          <img
            className="introduction__section-img"
            src={`${process.env.PUBLIC_URL}/maturity-question.png`}
            alt="Data-Driven Maturity Assessment question"
          />
        </div>
      </div>

      <div className="introduction__section">
        <div className="introduction__section-columns introduction__section-columns--reverse">
          <div className="introduction__copy">
            <h2>Personalized maturity report with step-by-step advice</h2>
            <p>
              The 5✗10 Data-Driven Maturity Report positions the organization on
              the maturity model and provides tailored information on your
              current situation. There is advice for every dimension for every
              stage of the maturity model. The advice in the report provides a
              clear blueprint for a strategic plan.
            </p>
          </div>
          <img
            className="introduction__section-img"
            src={`${process.env.PUBLIC_URL}/report-example.png`}
            alt="Data-Driven Maturity Assessment question"
          />
        </div>
      </div>

      <div className="introduction__section">
        <h2 className="h2--margin">
          A maturity framework born from modern research
        </h2>
        <p>
          The 5✗10 Data-Driven Maturity Model & Assessment have been developed
          by{' '}
          <a
            href="https://www.linkedin.com/in/ruben-buitelaar-a67b2b105/"
            target="_blank"
          >
            Ruben Buitelaar
          </a>{' '}
          at Leiden University as part of the master's degree in ICT in
          Business. The research involved careful meta-analysis of existing
          maturity models and review of existing best-practices. The full
          master's thesis can be found{' '}
          <a
            href="https://theses.liacs.nl/pdf/2017-2018-BuitelaarRuben.pdf"
            target="_blank"
          >
            here
          </a>
          .
        </p>
      </div>

      <Button to="/assessment">
        Start the 5✗10 Data-Driven Maturity Assessment
      </Button>
    </div>
  )
}

export default withRouter(Introduction)
