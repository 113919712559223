import data from 'data/dimensions/data'
import metrics from 'data/dimensions/metrics'
import skills from 'data/dimensions/skills'
import technology from 'data/dimensions/technology'
import leadership from 'data/dimensions/leadership'
import culture from 'data/dimensions/culture'
import strategy from 'data/dimensions/strategy'
import agility from 'data/dimensions/agility'
import integration from 'data/dimensions/integration'
import empowerment from 'data/dimensions/empowerment'

import reporting from 'data/stages/reporting'
import analyzing from 'data/stages/analyzing'
import optimizing from 'data/stages/optimizing'
import empowering from 'data/stages/empowering'
import innovating from 'data/stages/innovating'

import questionnaire from 'data/questionnaire'
import model from 'data/model'

export default {
  dimensions: {
    index: [
      'data',
      'metrics',
      'skills',
      'technology',
      'leadership',
      'culture',
      'strategy',
      'agility',
      'integration',
      'empowerment',
    ],
    data,
    metrics,
    skills,
    technology,
    leadership,
    culture,
    strategy,
    agility,
    integration,
    empowerment,
  },
  stages: {
    index: ['reporting', 'analyzing', 'optimizing', 'empowering', 'innovating'],
    reporting,
    analyzing,
    optimizing,
    empowering,
    innovating,
  },
  questionnaire,
  model,
}
