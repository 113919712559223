import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/likert.css'

class Likert extends React.Component {
  render() {
    if (!this.props.showMeta) return null

    const cl = bem('likert')

    const options = [
      'Strongly disagree',
      'Disagree',
      'Neutral',
      'Agree',
      'Strongly agree',
    ]

    return (
      <div className={cl.toString()} id={this.props.id}>
        <div className={cl('title').toString()}>{this.props.question}</div>
        <div className={cl('options').toString()}>
          {options.map((option, index) => (
            <div
              className={cl('option', {
                $selected: index === this.props.currentAnswer,
              }).toString()}
              onClick={() => this.props.answer(this.props.id, index)}
              key={option}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Likert
