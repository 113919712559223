import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/dimension.css'

import { Link } from 'react-router-dom'

import { Transition } from 'react-transition-group'
import MaturityQuestion from './maturityQuestion'
import Likert from './likert'
import Scalar from './scalar'
import { Button, Input } from 'components'
import { capitalize, info } from 'lib/utils'
import {
  useGoToTop,
  updateMetaAnswer,
  updateAnswer,
  firstUnanswered,
} from 'lib/common'

import { withRouter } from 'react-router-dom'

function dimensionNames(state) {
  return Object.keys(state.dimensions)
}

function nextDimension(state, dimensionKey) {
  return dimensionNames(state)[dimensionNames(state).indexOf(dimensionKey) + 1]
}

function next(state, dimensionKey, questions, answers, meta, dispatch) {
  const openQuestion = firstOpenQuestion(
    state.showMeta,
    answers,
    meta,
    questions
  )

  if (!openQuestion) {
    dispatch({
      type: 'SUBMIT_ANSWERS',
      payload: {
        key: dimensionKey,
        meta,
        answers,
      },
    })

    dispatch({
      type: 'NAVIGATE',
      payload: {
        url: nextDimension(state, dimensionKey) || '../report',
      },
    })
  } else {
    document.getElementById(openQuestion).classList.add('--unanswered')
    document.getElementById(openQuestion).scrollIntoView()
  }
}

function key(...keys) {
  return keys.map(k => k.replace(/ /g, '-').toLowerCase()).join('-')
}

function metaKeys(questions) {
  const metaKeys = questions.reduce(
    (keys, question) =>
      keys.concat([
        key(question.name, 'knowledge'),
        key(question.name, 'importance'),
      ]),
    []
  )

  return metaKeys.concat([key('purpose'), key('importance')])
}

function answerKeys(questions) {
  return questions.map(question => key(question.name))
}

// function firstUnanswered(answers, meta, questions) {
//   const allMeta = metaKeys(questions).find(
//     key => !Object.keys(meta).includes(key)
//   )
//   const allAnswers = answerKeys(questions).find(
//     key => !Object.keys(answers).includes(key)
//   )

//   return allAnswers || allMeta
// }

function firstOpenQuestion(showMeta, answers, meta, questions) {
  const allMeta = firstUnanswered(meta, metaKeys(questions))
  const allAnswers = firstUnanswered(answers, answerKeys(questions))

  return allAnswers || (showMeta && allMeta)
}

const defaultState = {
  answers: {},
  meta: {},
}

function Dimension({ state, dispatch, dimensionKey }) {
  const [localState, setState] = useState(defaultState)

  useGoToTop({ key: dimensionKey })

  useEffect(() => {
    setState(defaultState)
  }, [dimensionKey])

  const questions = state.questionnaire.dimensions[dimensionKey].questions
  const dimension = state.dimensions[dimensionKey]

  const answers = Object.assign(
    {},
    state.answers[dimensionKey],
    localState.answers
  )
  const meta = Object.assign({}, state.meta[dimensionKey], localState.meta)

  const cl = bem('dimension')

  return (
    <Transition in={true} appear={true} timeout={200}>
      {animationState => (
        <div
          className={cl('', {
            $entering: animationState === 'entering',
            $entered: animationState === 'entered',
            $exiting: animationState === 'exiting',
            $exited: animationState === 'exited',
          }).toString()}
        >
          <div className={cl('name').toString()}>{dimension.name}</div>
          <div
            className={cl('description').toString()}
            dangerouslySetInnerHTML={{ __html: info(dimension.short) }}
          />

          {questions.map((question, index) => {
            const questionKey = key(question.name)

            const keyKnowledge = key(question.name, 'knowledge')
            const keyImportance = key(question.name, 'importance')

            return (
              <div key={questionKey} className={cl('section').toString()}>
                <MaturityQuestion
                  in={true}
                  key={questionKey}
                  id={questionKey}
                  question={question}
                  currentAnswer={answers[questionKey]}
                  answer={(id, answer) =>
                    updateAnswer(setState, localState, id, answer)
                  }
                />

                <Likert
                  answer={(id, answer) =>
                    updateMetaAnswer(setState, localState, id, answer)
                  }
                  currentAnswer={meta[keyKnowledge]}
                  id={keyKnowledge}
                  question="I have enough knowledge about the organization to answer this question."
                  showMeta={state.showMeta}
                />

                <Scalar
                  answer={(id, answer) =>
                    updateMetaAnswer(setState, localState, id, answer)
                  }
                  id={keyImportance}
                  question="How important is this attribute for determining data-driven maturity?"
                  min="Not important at all"
                  max="Very important"
                  currentAnswer={meta[keyImportance]}
                  showMeta={state.showMeta}
                />
              </div>
            )
          })}

          <Likert
            answer={(id, answer) =>
              updateMetaAnswer(setState, localState, id, answer)
            }
            id={key('purpose')}
            currentAnswer={meta[key('purpose')]}
            question="The purpose of this dimension is clear."
            showMeta={state.showMeta}
          />

          <Scalar
            answer={(id, answer) =>
              updateMetaAnswer(setState, localState, id, answer)
            }
            id={key('importance')}
            currentAnswer={meta[key('importance')]}
            question="How important is this dimension for data-driven maturity?"
            min="Not important at all"
            max="Very important"
            showMeta={state.showMeta}
          />

          <Input
            description="Any additional remarks or suggestions for this dimension?"
            update={value =>
              updateMetaAnswer(setState, localState, key('random'), value)
            }
            value={meta[key('random')]}
            textarea
            showMeta={state.showMeta}
          />

          <Button
            onClick={() =>
              next(state, dimensionKey, questions, answers, meta, dispatch)
            }
            block
          >
            Continue
          </Button>
        </div>
      )}
    </Transition>
  )
}

export default withRouter(Dimension)
