const clean = text => {
  return text
    .replace(/\[.*?\]/g, '')
    .replace(/\``(.*?)''/g, (match, p1) => `\"${p1}\"`)
    .replace(/\`(.*?)'/g, (match, p1) => `\'${p1}\'`)
    .replace(/\\textdollar/g, '$')
    .replace(/\\textit{(.*?)}/g, (match, p1) => `${p1}`)
    .replace(/\\cite{(.*?)}/g, '')
    .replace(/\\\%/g, '%')
}

const dictionary = {
  'analytical data': 'Data collected to be used in analysis',
  analytics:
    'Business intelligence and business analytics activities; Turning data into action',
  'data lake':
    'Accessible data warehousing where data is largely kept in its original form',
  IoT: 'Internet of Things',
  KPI: 'Key Performance Indicator',
  'analytical model':
    'Data fueled model that is able to predict future trends or behavior',
  'machine learning':
    'Using known behavior to learn a system to predict future behavior',
  'analytical product':
    'Product or service with built-in analytical capabilities (e.g. smart thermometer)',
  'data literacy': 'The ability to interpret and use statistics and data',
  'data-driven':
    'Using data to base decisions on evidence rather than intuition',
}

const info = text => {
  for (let key of Object.keys(dictionary)) {
    const re = new RegExp(`(${key})`, 'gi')
    text = text.replace(
      re,
      (match, p1) =>
        `<span class="dict">
          ${p1}
          <span class="dict__definition">
            ${dictionary[key]}
          </span>
        </span>`
    )
  }
  return text
}

const capitalize = text => text.charAt(0).toUpperCase() + text.slice(1)

const score = questions => {
  const scores = {}
  const max = 5

  for (let category in questions) {
    let score = 0

    for (let i = 1; i <= max; i++) {
      const relevantQuestions = questions[category].filter(q => q.max >= i)
      const questionValue = 1 / relevantQuestions.length
      for (let question of relevantQuestions) {
        if (question.score >= i) {
          score += questionValue
        }
      }
    }

    scores[category] = score
  }

  return scores
}

export { clean, capitalize, score, info }

export default {}
