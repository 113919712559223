import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import bem from 'bem-classes'

import Assessment from 'containers/assessment'
import Introduction from 'containers/introduction'
import MaturityModel from 'containers/maturity-model'

import 'styles/blocks/app.css'
import 'styles/blocks/alert.css'
import 'styles/blocks/brand.css'

import Header from './header'

const App = () => {
  const app = bem('app')

  console.log(app.toString())

  return (
    <div className={app.toString()}>
      <Helmet>
        <title>
          The Fiveten Data-Driven Maturity Framework - Free Maturity Assessment
        </title>
        <meta
          name="description"
          content="Bring your organization to the next level.
    Take our free analytics maturity assessment and access valuable insight and recommendations."
        />
      </Helmet>

      <Header />

      <main className={app('main').toString()}>
        <Route exact strict path="/" component={Introduction} />
        <Route strict path="/assessment" component={Assessment} />
        <Route strict path="/model" component={MaturityModel} />
      </main>

      <footer className={app('footer', 'no-print').toString()}>
        <div>5✗10 Data-Driven Maturity</div>
      </footer>
    </div>
  )
}

export default App
