import moment from 'moment'
import data from 'data'
import { uploadAssessment, retrieveAssessment } from 'lib/thunks'

const initialState = {
  showMeta: false,
  answers: {},
  meta: {
    start: new Date(),
    version: '2.0',
  },
  submitted: false,
  date: moment().format('Do of MMMM, YYYY'),
  dimensions: data.dimensions,
  questionnaire: data.questionnaire,
  dimension: false,
  pushedURL: '',
}

function saveState(state) {
  window.state = state
  localStorage.setItem('state', JSON.stringify(state))
}

function loadState(state) {
  const savedState = JSON.parse(localStorage.getItem('state'))
  return savedState && Object.keys(savedState).length ? savedState : state
}

function retrievedAssessment(state, { id, assessment }) {
  return Object.assign({}, state, {
    meta: assessment.meta,
    answers: assessment.answers,
    submitted: id,
    date: assessment.date,
  })
}

function submitAssessment(state, { payload }) {
  const newMeta = Object.assign(
    {
      end: new Date(),
    },
    state.meta,
    payload.meta
  )

  const newState = Object.assign({}, state, {
    meta: newMeta,
  })

  uploadAssessment(payload.dispatch, newState)

  return newState
}

function submittedAssessment(state, { payload }) {
  return Object.assign({}, state, {
    submitted: payload.submitted,
    date: payload.date,
  })
}

function reset(_state, _action) {
  localStorage.removeItem('state')
  return initialState
}

function submitAnswers(state, { payload }) {
  return Object.assign({}, state, {
    meta: Object.assign({}, state.meta, {
      [payload.key]: payload.meta,
    }),
    answers: Object.assign({}, state.answers, {
      [payload.key]: payload.answers,
    }),
  })
}

function navigate(state, { payload }) {
  return Object.assign({}, state, {
    pushedURL: payload.url,
  })
}
function pushedURL(state, _event) {
  return Object.assign({}, state, {
    pushedURL: false,
  })
}

function stateMachine(state, action) {
  console.log(action.type, action.payload)

  switch (action.type) {
    case 'RESET':
      return reset(state, action)
    case 'SUBMIT_ANSWERS':
      return submitAnswers(state, action)
    case 'NAVIGATE':
      return navigate(state, action)
    case 'PUSHED_URL':
      return pushedURL(state, action)
    case 'SUBMIT_ASSESSMENT':
      return submitAssessment(state, action)
    case 'SUBMITTED_ASSESSMENT':
      return submittedAssessment(state, action)
    case 'RETRIEVE_ASSESSMENT':
      return retrieveAssessment(state, action.payload)
    case 'RETRIEVED_ASSESSMENT':
      return retrievedAssessment(state, action.payload)
    default:
      return state
  }
}

function init(state) {
  const newState = loadState(state)
  console.log(newState)
  window.state = newState
  return newState
}

function reducer(state, action) {
  const newState = stateMachine(state, action)
  saveState(newState)
  return newState
}

export { initialState, reducer, init }
