import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/report.css'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Chart from './chart'
import { Button, Input, Model } from 'components'
import { clean, capitalize } from 'lib/utils'
import { useGoToTop, updateMetaAnswer } from 'lib/common'
import data from 'data'
import moment from 'moment'

import Scalar from './scalar'
import Likert from './likert'

function score(questions, answers) {
  const scores = {}
  const max = 5

  for (let key of Object.keys(questions.dimensions)) {
    const dimension = questions.dimensions[key]
    let score = 0
    for (let i = 1; i <= max; i++) {
      const relevantQuestions = dimension.questions.filter(
        q => q.options.length >= i
      )
      const questionValue = 1 / relevantQuestions.length
      for (let question of relevantQuestions) {
        const answerKey = question.name.replace(/ /g, '-').toLowerCase()
        const answer = answers[key][answerKey]
        if (answer && answer >= i) {
          score += questionValue
        }
      }
    }

    scores[key] = score.toFixed(1)
  }

  return scores
}

function permalink(submitted) {
  const url = new URL(
    'https://data-driven.rubenbuitelaar.com/assessment/report'
  )
  url.searchParams.set('id', submitted)

  return url.href
}

// function pdfURL(meta) {
//   const url2pdf = new URL(
//     'https://report.rubenbuitelaar.com/api/render?emulateScreenMedia=false&pdf.margin.top=2cm&pdf.margin.right=2cm&pdf.margin.bottom=2cm&pdf.margin.left=2cm'
//   )

//   const date = moment(meta.start).format('DD-MM-YYYY')
//   const orgName = meta.general['organization-name'].replace(/( |\.)/g, '')
//   url2pdf.searchParams.set(
//     'attachmentName',
//     `DataDrivenMaturityReport-${orgName}-${date}.pdf`
//   )
//   url2pdf.searchParams.set('url', permalink())

//   return url2pdf.href
// }

function allAnswered(showMeta, meta) {
  const metaKeys = [
    'report-data-accuracy',
    'report-metrics-accuracy',
    'report-skills-accuracy',
    'report-technology-accuracy',
    'report-leadership-accuracy',
    'report-culture-accuracy',
    'report-strategy-accuracy',
    'report-agility-accuracy',
    'report-integration-accuracy',
    'report-empowerment-accuracy',
    'model-purpose',
    'model-scope',
    'report-results',
    'report-overall-accuracy',
    'report-nps',
  ]
  const allMeta = metaKeys.find(key => !Object.keys(meta).includes(key))

  return !allMeta || !showMeta
}

function reset(dispatch) {
  dispatch({ type: 'RESET_ASSESSMENT' })
}

function Report({ global, dispatch }) {
  if (!Object.entries(global.answers).length) return <div />

  const [state, setState] = useState({
    meta: global.meta || {},
    date: global.date,
    isReset: global.isReset || false,
    showMeta: global.showMeta,
  })

  useGoToTop()

  // if (state.isReset) {
  //   return <Redirect to="/assessment" />
  // }

  useEffect(() => {
    if (!global.showMeta && !global.submitted) {
      dispatch({
        type: 'SUBMIT_ASSESSMENT',
        payload: { dispatch, meta: state.meta },
      })
    }
  }, true)

  const cl = bem('report', {
    $submitted: global.submitted,
  })
  const scores = score(data.questionnaire, global.answers)
  const maturityScore = (
    Object.values(scores).reduce((a, b) => a + parseFloat(b), 0) / 10
  ).toFixed(1)

  const dimensions = [
    'data',
    'metrics',
    'skills',
    'technology',
    'leadership',
    'culture',
    'strategy',
    'agility',
    'integration',
    'empowerment',
  ]

  const stages = [
    'starting',
    'reporting',
    'analyzing',
    'optimizing',
    'empowering',
    'innovating',
  ]

  return (
    <div className={cl.toString()}>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>
          5✗10 Maturity Report - {global.meta.general['organization-name']}
        </title>
      </Helmet>

      <div className={cl('info').toString()}>
        <div className="no-print">
          You have successfully completed the 5✗10 Maturity Assessment. Below
          are the results in a personalized maturity report. I hope you can use
          this report as a blueprint for creating a strategy for your
          organization. If you have any thoughts or feedback, or just want to
          connect, please add me on{' '}
          <a
            target="_blank"
            href="https://www.linkedin.com/in/ruben-buitelaar-a67b2b105/"
          >
            LinkedIn
          </a>
          .
        </div>

        {!global.submitted ? (
          <p className="no-print alert alert--green">
            Please read the report and answer the final questions.
          </p>
        ) : (
          <>
            <p className="no-print alert alert--green">
              Permalink to your report and results (share at your own
              discretion):
              <br />
              <a
                className={cl('link').toString()}
                href={permalink(global.submitted)}
              >
                {permalink(global.submitted)}
              </a>
            </p>
            <div className={cl('buttons').toString()}>
              <Button
                className={`no-print`}
                onClick={() =>
                  window.confirm(
                    'Are you sure you want to reset the assessment?'
                  ) && reset(dispatch)
                }
              >
                Restart assessment
              </Button>
            </div>
          </>
        )}

        <div className={cl('title').toString()}>
          Data-Driven Maturity Report
          <br />
          Score: {maturityScore}/5
        </div>

        <div className={cl('meta').toString()}>
          <div className={cl('meta-row').toString()}>
            <div>Organization:</div>
            <div>
              {global.meta.general['organization-name']}
              {global.meta.general['scope'] &&
                ` (${global.meta.general['scope']})`}
            </div>
          </div>
          <div className={cl('meta-row').toString()}>
            <div>Evaluator:</div>
            <div>{global.meta.general['evaluator-name']}</div>
          </div>
          <div className={cl('meta-row').toString()}>
            <div>Role:</div>
            <div>{global.meta.general['role']}</div>
          </div>
          <div className={cl('meta-row').toString()}>
            <div>Date:</div>
            <div>{global.date}</div>
          </div>
        </div>
      </div>
      <div className={cl('score').toString()} />
      <div className={cl('model').toString()}>
        <Model maturityScore={maturityScore} />
      </div>
      <div className={cl('overview').toString()}>
        <div className={cl('overview-score').toString()}>
          {dimensions.map(dimension => {
            return (
              <div
                className={cl('overview-dimension').toString()}
                key={`overview-dimension-${dimension}`}
              >
                <div
                  className={cl('overview-dimension-progress', {
                    [`$level-${Math.floor(scores[dimension])}`]: true,
                  }).toString()}
                  style={{
                    width: `${Math.max((scores[dimension] / 5) * 100, 5)}%`,
                  }}
                />
                <div className={cl('overview-dimension-score').toString()}>
                  {capitalize(dimension)}: {scores[dimension]}
                </div>
              </div>
            )
          })}
        </div>
        <Chart scores={scores} />
      </div>
      <div className={cl('reading-guide').toString()}>
        <h2>Reading Guide</h2>
        <span>{data.model.readingGuide}</span>
      </div>
      {dimensions.map(dimension => {
        const level = Math.floor(scores[dimension])
        const asIsStage = stages[level]
        const toBeStage = stages[Math.min(level + 1, 5)]
        return (
          <div key={dimension} className={cl('dimension').toString()}>
            <div
              className={cl('dimension-progress', {
                [`$level-${Math.floor(scores[dimension])}`]: true,
              }).toString()}
              style={{
                width: `${Math.max((scores[dimension] / 5) * 100, 5)}%`,
              }}
            />
            <div className={cl('dimension-header').toString()}>
              <div className={cl('dimension-name').toString()}>
                {capitalize(dimension)}
              </div>
              <div className={cl('dimension-stage').toString()}>
                {asIsStage === toBeStage
                  ? `${capitalize(asIsStage)}`
                  : `${capitalize(asIsStage)} → ${capitalize(toBeStage)}`}
              </div>
              <div className={cl('dimension-score').toString()}>
                {scores[dimension]}
              </div>
            </div>
            {clean(data.stages[toBeStage][dimension]['text'])}
            <ul className={cl('action-list').toString()}>
              {data.stages[toBeStage][dimension]['actionList'].map(item => (
                <li key={item} className={cl('action-item').toString()}>
                  {item}
                </li>
              ))}
            </ul>

            <Scalar
              answer={(id, answer) =>
                updateMetaAnswer(setState, state, id, answer)
              }
              id={`report-${dimension}-accuracy`}
              question="How accurate is the maturity score for this dimension?"
              min="The score does not reflect our maturity level at all"
              max="The score perfectly reflects our maturity level"
              currentAnswer={state.meta[`report-${dimension}-accuracy`]}
              showMeta={state.showMeta}
            />
          </div>
        )
      })}
      <div className="no-show">
        <h2>Finishing up</h2>
        <Likert
          answer={(id, answer) => updateMetaAnswer(setState, state, id, answer)}
          id={'model-purpose'}
          question="The purpose of the Data-Driven Maturity Assessment is clear."
          currentAnswer={state.meta['model-purpose']}
          showMeta={state.showMeta}
        />
        <Likert
          answer={(id, answer) => updateMetaAnswer(setState, state, id, answer)}
          id={'model-scope'}
          question="The scope of the Data-Driven Maturity Model and Assessment are clear."
          currentAnswer={state.meta['model-scope']}
          showMeta={state.showMeta}
        />
        <Likert
          answer={(id, answer) => updateMetaAnswer(setState, state, id, answer)}
          id={'report-results'}
          question="The results of the Data-Driven Maturity Assessment can help us create a strategic plan for data-driven maturity."
          currentAnswer={state.meta['report-results']}
          showMeta={state.showMeta}
        />
        <Scalar
          answer={(id, answer) => updateMetaAnswer(setState, state, id, answer)}
          id={'report-overall-accuracy'}
          question="How accurate is the overall maturity score for your data-driven maturity?"
          min="The score does not reflect the maturity level at all"
          max="The score perfectly reflects the maturity level"
          currentAnswer={state.meta['report-overall-accuracy']}
          showMeta={state.showMeta}
        />
        <Scalar
          answer={(id, answer) => updateMetaAnswer(setState, state, id, answer)}
          id={'report-nps'}
          question="How likely is it that you would recommend this Data-Driven Maturity Assessment within your business network, without the added meta-evaluation (in pink)?"
          min="Not likely at all"
          max="Very likely"
          currentAnswer={state.meta['report-nps']}
          showMeta={state.showMeta}
        />

        <Input
          description="Any additional remarks or suggestions about the model, assessment, or report?"
          update={value =>
            updateMetaAnswer(setState, state, 'report-random', value)
          }
          value={state.meta['report-random']}
          textarea
          showMeta={state.showMeta}
        />
      </div>
      {!global.submitted && allAnswered(global.showMeta, state.meta) && (
        <Button
          block
          onClick={() =>
            dispatch({
              type: 'SUBMIT_ASSESSMENT',
              payload: { dispatch, meta: state.meta },
            })
          }
        >
          Submit assessment
        </Button>
      )}
      {!global.submitted && !allAnswered(global.showMeta, state.meta) && (
        <Button block>Please answer all questions before submitting</Button>
      )}
    </div>
  )
}

export default Report
