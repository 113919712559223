import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/maturity-question.css'

import { info } from 'lib/utils'

class MaturityQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hoverIndex: -1,
    }
  }

  enterAnswer(index) {
    this.setState({
      hoverIndex: index,
    })
  }

  leaveAnswer(index) {
    if (index === this.state.hoverIndex) {
      this.setState({
        hoverIndex: -1,
      })
    }
  }

  render() {
    const { question } = this.props
    const cl = bem('maturity-question')
    const options = question.options.map((option, index) => (
      <div
        key={index}
        className={cl('option', {
          $highlighted: index <= this.state.hoverIndex,
          $answered: index === this.props.currentAnswer,
        }).toString()}
        onMouseEnter={() => this.enterAnswer(index)}
        onMouseLeave={() => this.leaveAnswer(index)}
        onClick={() => this.props.answer(this.props.id, index)}
      >
        <div className={cl('index').toString()}>{index}</div>
        <div
          className={cl('description').toString()}
          dangerouslySetInnerHTML={{ __html: info(option) }}
        />
      </div>
    ))

    return (
      <div className={cl.toString()} id={this.props.id}>
        <div className={cl('title').toString()}>
          {question.name} -{' '}
          <span className={cl('info').toString()}>
            Please select the statement best fitting for the current state of
            your organization
          </span>
        </div>
        {options}
      </div>
    )
  }
}

export default MaturityQuestion
