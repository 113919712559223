import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/instructions.css'
import { Link } from 'react-router-dom'

import { Button } from 'components'
import { useGoToTop } from '../../lib/common'

function Instructions({ state, dispatch }) {
  useGoToTop()

  const cl = bem('instructions')

  return (
    <div className={cl.toString()}>
      {state.submitted && (
        <Button to="/assessment/report">Go to finished report</Button>
      )}

      <h1>The 5✗10 Data-Driven Maturity Assessment</h1>

      <h2>Instructions</h2>
      {state.showMeta ? (
        <p>
          The assessment consists of a maturity assessment and a meta-evaluation
          of the assessment and model, used to validate the maturity model for
          research purposes.
        </p>
      ) : (
        <p>
          The assessment consists of a comprehensive maturity assessment in the
          form of multiple-choice questions.
        </p>
      )}
      <h2>Purpose</h2>
      <p>
        The goal of the assessment is to gain insight into the data-driven
        maturity level of your organization in a fast and accessible manner.
        After the assessment a report will be compiled with the results and
        information tailored to your maturity situation. We believe the report
        can be the starting point of your data-driven strategy. Because of the
        limitations of a self-assessment the goal is not to use this assessment
        to compare maturity to other organizations.
      </p>
      <h2>Questions</h2>
      <p>
        Questions for the maturity assessment are multiple-choice. Each choice
        is a statement corresponding to a certain maturity level for a specific
        attribute of the dimension, progressing from immature to full maturity.
        To answer the question, please read all statements carefully and select
        the most advanced statement that represents the current state in the
        organization.
      </p>
      <h2>Who should fill out this assessment?</h2>
      <p>
        The questions are made to assess the current state of data-driven
        maturity in the organization in an accessible manner. The most suitable
        assessor is someone in the organization with a good overview on the
        following subjects: data, metrics, skills, technology, data-driven
        culture, leadership, strategy, execution (of processes), analytical
        integration, and analytical empowerment of employees. The questionnaire
        requires no very specific knowledge. The most suitable evaluator would
        likely be a Chief Information or Technology Officer.
      </p>
      <h2>What is data-driven?</h2>
      <p>
        Becoming data-driven is the quest to making better decisions as an
        organization. Data-driven means using data to create evidence supporting
        a decision opposed to relying on our intuition. Analytics, the field of
        business intelligence and business analytics, aims to collect, process,
        and analyze data to produce information that can help the
        decision-making process.
      </p>
      <h2>A note on Internet Explorer</h2>
      <p>
        We recommend completing this assessment in a modern browser (Google
        Chrome, Firefox, Microsoft Edge). The visuals are optimized for modern
        browsers. Some users report certain quirks in Internet Explorer and were
        unable to complete the assessment. We are working on a fix.
      </p>
      {state.showMeta && (
        <p className={cl('research').toString()}>
          The Data-Driven Maturity Model and the accompanying assessment are
          part of the graduate research by Ruben Buitelaar for the Master's
          degree ICT in Business at Leiden University. The parts of the
          assessment in pink form a meta-evaluation conducted to validate the
          maturity model and assessment. Questions in pink are not relevant for
          your maturity score, but help us in improving the model and
          assessment.
          <br />
          <b>Results will stay anonymous and confidential.</b>
        </p>
      )}
      <Button to={`/assessment/general`} block>
        Start Assessment
      </Button>
    </div>
  )
}

export default Instructions
