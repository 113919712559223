import React, { useState } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/stages.css'

import { Button, Input, Model } from 'components'
import Likert from './likert'
import Scalar from './scalar'
import { clean, info, capitalize } from 'lib/utils'
import data from 'data'
import { Link } from 'react-router-dom'
import { useGoToTop, updateMetaAnswer, firstUnanswered } from '../../lib/common'

function allAnswered(state, showMeta) {
  const metaKeys = [
    'stage-reporting-purpose',
    'stage-reporting-importance',
    'stage-analyzing-purpose',
    'stage-analyzing-importance',
    'stage-optimizing-purpose',
    'stage-optimizing-importance',
    'stage-empowering-purpose',
    'stage-empowering-importance',
    'stage-innovating-purpose',
    'stage-innovating-importance',
  ]
  const allMeta = firstUnanswered(state.meta, metaKeys)

  return !showMeta || !allMeta
}

function Stages({ state, dispatch }) {
  const [localState, setState] = useState({
    answers: {},
    meta: state.meta.stages || {},
  })

  useGoToTop()

  const cl = bem('stages')

  const stages = [
    'reporting',
    'analyzing',
    'optimizing',
    'empowering',
    'innovating',
  ]

  return (
    <div className={cl.toString()}>
      <h2>Stages</h2>
      <div>
        We first quickly introduce the stages of the data-driven maturity model.
        It will help in defining a frame of reference for answering the
        assessment questions.
      </div>

      {stages.map(stage => (
        <div className={cl('stage').toString()} key={stage}>
          <h2>{capitalize(stage)}</h2>
          <Model highlight={stage} />
          <div
            className={cl('stage-description').toString()}
            dangerouslySetInnerHTML={{
              __html: info(clean(data.stages[stage].full)),
            }}
          />

          <Likert
            showMeta={state.showMeta}
            id={`stage-${stage}-purpose`}
            question="The purpose of this stage is clear"
            answer={(key, answer) =>
              updateMetaAnswer(setState, localState, key, answer)
            }
            currentAnswer={localState.meta[`stage-${stage}-purpose`]}
          />

          <Scalar
            showMeta={state.showMeta}
            id={`stage-${stage}-importance`}
            question="How important is this stage in data-driven maturity?"
            answer={(id, answer) =>
              updateMetaAnswer(setState, localState, id, answer)
            }
            currentAnswer={localState.meta[`stage-${stage}-importance`]}
            min="Not important at all"
            max="Very important"
          />
        </div>
      ))}

      <Input
        showMeta={state.showMeta}
        description="Any additional remarks or suggestions about these stages?"
        update={value =>
          updateMetaAnswer(setState, localState, 'stage-random', value)
        }
        value={localState.meta['stage-random']}
        textarea
      />

      {allAnswered(localState, state.showMeta) ? (
        <Button
          to="/assessment/dimensions/data"
          onClick={() =>
            dispatch({
              type: 'SUBMIT_ANSWERS',
              payload: {
                key: 'stages',
                meta: localState.meta,
                answers: localState.answers,
              },
            })
          }
          block
        >
          Continue
        </Button>
      ) : (
        <Button block>Please answer all questions before continuing</Button>
      )}
    </div>
  )
}

export default Stages
