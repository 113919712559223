import { useGoToTop } from 'lib/common'
import { withRouter } from 'react-router-dom'

const ScrollToTop = ({ children, location: { pathname } }) => {
  useGoToTop({ key: pathname, behavior: 'auto' })

  return children || null
}

export default withRouter(ScrollToTop)
