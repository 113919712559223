import 'url-polyfill'
import React from 'react'
import { render } from 'react-dom'
import { Router } from 'react-router-dom'
import App from 'containers/app'
import 'styles/base.css'
import 'styles/blocks/fiveten.css'
import { createBrowserHistory } from 'history'
import { ScrollTop } from 'components'

const target = document.querySelector('#root')
const history = createBrowserHistory()

history.listen(location => {
  if (!window.ga) return

  window.ga('send', 'pageview', location.pathname)
})

render(
  <Router history={history}>
    <ScrollTop>
      <App />
    </ScrollTop>
  </Router>,
  target
)

if (module.hot) {
  module.hot.accept('containers/app', () => {
    import('containers/app').then(module => {
      const NextApp = module.default

      render(
        <Router history={history}>
          <ScrollTop>
            <NextApp />
          </ScrollTop>
        </Router>,
        target
      )
    })
  })
}
