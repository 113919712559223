import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import { Link } from 'react-router-dom'
import 'styles/blocks/button.css'

const Button = props => {
  const { className, onClick, block } = props
  const to = props.to || '#'
  const button = `button ${block ? 'block' : ''} ${className}`
  return (
    <div className="button__container">
      <Link to={to} onClick={onClick} className={button}>
        {props.children}
      </Link>
    </div>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  block: PropTypes.bool,
}

Button.defaultProps = {
  className: '',
  onClick: () => {},
  block: false,
}

export default Button
