import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem-classes'
import 'styles/blocks/input.css'

class Input extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (!this.props.showMeta) return null

    const input = bem('input', { $meta: this.props.textarea })

    return (
      <div className={input.toString()}>
        <div className={input('description').toString()}>
          {this.props.description}
        </div>
        {this.props.textarea ? (
          <textarea
            className={input('field').toString()}
            onChange={e => this.props.update(e.target.value)}
            placeholder={this.props.placeholder}
            value={this.props.value || ''}
          />
        ) : (
          <input
            type="text"
            className={input('field').toString()}
            onChange={e => this.props.update(e.target.value)}
            placeholder={this.props.placeholder}
            value={this.props.value || ''}
          />
        )}
      </div>
    )
  }
}

export default Input
